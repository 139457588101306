import { useEffect, useRef } from 'react';

import classes from './Caption.module.css';

function Caption(props) {
//   const start = props.start;
//   const duration = props.duration;
  const active = props.active;
  const start = props.start;

  const captionRef = useRef();

  useEffect(() => {
    if(props.active === true) {
      console.log("useEffect YESSS");
      const { top } = captionRef.current.getBoundingClientRect();
      // console.log(top);
      // const isOutOfView = props.isOutOfView(top);
  
      // if(isOutOfView) {
      //   captionRef.current.scrollIntoView({ behavior: "instant" });
      // }
      props.scrollIntoView(captionRef, top);
    }
  }, [props]);

  function handleSeek() {
    props.onSeek(start);
  }

  return <span ref={captionRef}><span onClick={handleSeek} className={`${classes.caption} ${active ? classes.activeCaption : null}`}>{props.text}</span><span>&nbsp;</span></span>;
}

export default Caption;
