import React from "react";

import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";

import IconButton from "../UI/IconButton";
import classes from "./NewNotesLayout.module.css";
import { signInGoogle, signOutGoogle } from "../../store/auth-actions";

//import backIcon from '../../assets/Icons/back-icon.png';
import backIconRed from "../../assets/Icons/back-icon-red.png";
//import backIconPurple from '../../assets/Icons/back-icon-purple.png';
//import loginIcon from '../../assets/Icons/login-icon.png';
import loginIconRed from "../../assets/Icons/login-icon-red.png";
import logoutIconRed from "../../assets/Icons/logout-icon-red.png";
//import loginIconPurple from '../../assets/Icons/login-icon-purple.png';
import plusIcon from "../../assets/Icons/plus-icon.png";
import exportPdfIcon from "../../assets/Icons/export-pdf-icon.png";
import TextButton from "../UI/TextButton";
import ActionIcon from "../UI/ActionIcon";

function NewNotesLayout(props) {
  const navigate = useNavigate();
  const isLoggedIn = useSelector((state) => state.auth.isLoggedIn);
  //const userData = useSelector((state) => state.auth.userData);
  const dispatch = useDispatch();

  function backNavigationHandler() {
    navigate(-1);
  }

  function myVideosNavigationHandler() {
    navigate("/videos");
  }

  function signInHandler() {
    dispatch(signInGoogle());
  }

  function signOutHandler() {
    dispatch(signOutGoogle());
    navigate("/");
  }

  function handleAllNotesClick() {
    if (isLoggedIn) {
      props.onSelectAllNotesView();
    } else {
      return;
    }
  }

  async function handleAIGenerate() {
    props.handleAIGenerate();
  }

  async function handleGeneratePDF() {
    props.onExportPDF();
  }

  return (
    <div className={classes.notesSection}>
      <div className={classes.navigation}>
        <ActionIcon
          icon={backIconRed}
          onClick={backNavigationHandler}
          tooltip="Back"
        />
        {isLoggedIn && <div onClick={myVideosNavigationHandler}>My Videos</div>}
        {/* <IconButton icon={backIcon} tooltip="Back"/> */}
        {/* <TextButton text="My Videos"/> */}
        {/* <IconButton icon={loginIcon} tooltip="Login"/> */}
        <ActionIcon
          icon={isLoggedIn ? logoutIconRed : loginIconRed}
          onClick={isLoggedIn ? signOutHandler : signInHandler}
          tooltip={isLoggedIn ? "Logout" : "Login"}
        />
      </div>
      <hr />
      <div className={`${"flex justify-evenly pt-2 pb-3"} ${classes.tabs}`}>
        <div
          onClick={() => {
            props.onSelectView("allNotes");
          }}
          className={`${"w-1/3 text-center p-2"} ${classes["tab"]} ${
            classes["border-tab"]
          } ${
            props.selectedView === "editNote" ||
            props.selectedView === "allNotes"
              ? classes.activeTab
              : null
          }`}
        >
          Notes
        </div>
        <div
          onClick={() => {
            props.onSelectView("description");
          }}
          className={`${"w-1/3 text-center p-2"} ${classes["tab"]} ${
            classes["border-tab"]
          } ${props.selectedView === "description" ? classes.activeTab : null}`}
        >
          Description
        </div>
        <div
          onClick={() => {
            props.onSelectView("transcript");
            props.onClickTranscript();
          }}
          className={`${"w-1/3 text-center p-2"} ${classes["tab"]} ${
            props.selectedView === "transcript" ? classes.activeTab : null
          }`}
        >
          Transcript
        </div>
      </div>
      {/* <div className={`${classes.selectView} ${props.selectedView === "newNote" ? classes["align-right"] : classes["align-left"]}`}> */}
      {(props.selectedView === "editNote" ||
        props.selectedView === "allNotes") && (
        <div className={classes.selectView}>
          <div className={classes.selectViewLeft}>
            {props.selectedView === "allNotes" && (
              <IconButton
                icon={plusIcon}
                onClick={props.onSelectEditNoteView}
                tooltip={"Add Note"}
              />
            )}
            {props.selectedView === "editNote" && (
              <TextButton
                text="All Notes"
                onClick={handleAllNotesClick}
                tooltip={!isLoggedIn ? "Please login to view your notes" : null}
              />
            )}
            <div className={classes.verticalGap} />
            <IconButton
              icon={exportPdfIcon}
              onClick={
                isLoggedIn && !props.generatingPDF
                  ? handleGeneratePDF
                  : () => {}
              }
              tooltip={
                isLoggedIn ? "Save as PDF" : "Please login to save notes as PDF"
              }
            />
          </div>
          <TextButton
            text="AI Generate"
            onClick={isLoggedIn ? handleAIGenerate : null}
            tooltip={
              isLoggedIn
                ? "Let AI generate video notes"
                : "Please Login to use this feature"
            }
          />
        </div>
      )}
      {props.generatingAINotes && (
        <p className="text-center">
          Please wait. This may take a few seconds...
        </p>
      )}
      {props.generatingPDF && <p className="text-center">Generating PDF...</p>}
      <div className={classes.children}>{props.children}</div>
    </div>
  );
}

export default NewNotesLayout;
