import React from "react";
import TwitterIcon from "../../assets/Icons/x-twitter-brands-solid.svg"; // Importing Twitter icon
import TwitterIconBlue from "../../assets/Icons/twitter-blue.svg";

const Footer = () => {
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  return (
    <footer className="bg-[#0f0e17] py-6">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        {/* Flex container for footer content */}
        <div className="flex flex-col md:flex-row justify-between items-center">
          {/* Left Section: Copyright + Follow Us on X */}
          <div className="flex items-center space-x-4">
            {/* Copyright Text */}
            <div className="text-gray-400 text-sm">
              © {new Date().getFullYear()} ZippyNotes.co
            </div>

            {/* Follow Us on X */}
            <a
              href="https://x.com/zippynotes"
              target="_blank"
              rel="noopener noreferrer"
              className="flex items-center text-gray-400 hover:text-[#1DA1F2] transition duration-0 group"
              aria-label="Follow us on X (formerly Twitter)"
            >
              <span className="text-sm">Follow us</span>
              <img
                src={TwitterIcon}
                alt="X (formerly Twitter)"
                className="h-4 w-4 ml-1 group-hover:hidden"
              />
              <img
                src={TwitterIconBlue}
                alt="X (formerly Twitter)"
                className="h-4 w-4 ml-1 hidden group-hover:inline"
              />
            </a>
          </div>

          {/* Right Section: Scroll to Top */}
          <button
            onClick={scrollToTop}
            className="text-gray-400 hover:text-white text-sm transition duration-200 mt-4 md:mt-0"
          >
            Scroll to top ↑
          </button>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
