import { configureStore } from '@reduxjs/toolkit';

import authReducer from './auth-slice';
//import { verifyAuth } from './auth-actions';

var store = configureStore({
    reducer: {
        auth: authReducer,
    }
});

export default store;