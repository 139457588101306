// Import the functions you need from the SDKs you need
import { initializeApp, getApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getAuth, GoogleAuthProvider } from "firebase/auth";
import { getFirestore } from 'firebase/firestore';
import { getFunctions, connectFunctionsEmulator } from 'firebase/functions';
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries
// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyBHsjqy_-n0IffkWh5Rq8l25pbt4HAU3jY",
  authDomain: "zippynotes.co",
  projectId: "zippy-notes",
  storageBucket: "zippy-notes.appspot.com",
  messagingSenderId: "986186194723",
  appId: "1:986186194723:web:32e8a54834f35146077696",
  measurementId: "G-NLP2691PZ0"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const functions = getFunctions(getApp());
connectFunctionsEmulator(functions, "127.0.0.1", 5001);
//const analytics = getAnalytics(app);
getAnalytics(app);
export const auth = getAuth(app);

export const provider = new GoogleAuthProvider();

/* export async function signInWithGoogle() {
    return signInWithPopup(auth, provider).then((result) => {
        var token = result.credential.accessToken;
        var user = result.user;
        console.log(token);
        console.log(user);
    }).catch((error) => {

    });
} */

export const db = getFirestore(app);