import React from "react";
import OnlineEducationIcon from "../../assets/ZippyNotes-logo/Use Case Images/book-solid grey.svg";
import VideoResearchIcon from "../../assets/ZippyNotes-logo/Use Case Images/magnifying-glass-solid.svg";
import VideoEntertainmentIcon from "../../assets/ZippyNotes-logo/Use Case Images/music-solid.svg";

const NewUseCasesSection = () => {
  const useCases = [
    {
      title: "Improve note-taking for online education",
      description:
        "Keep track of important information and revisit key concepts more efficiently. ZippyNotes helps you to take timestamped notes while watching educational videos on YouTube.",
      icon: OnlineEducationIcon,
    },
    {
      title: "Improve video-based research",
      description:
        "Streamline your research process and find relevant information within video content easily. Take detailed notes and annotate specific moments in a video on ZippyNotes.",
      icon: VideoResearchIcon,
    },
    {
      title: "Enhance video-based entertainment",
      description:
        "Take notes on entertaining videos, music videos, or podcasts. Save the best moments of your favorite videos!",
      icon: VideoEntertainmentIcon,
    },
  ];

  return (
    <section className="bg-[#0f0e17] py-16 md:py-20">
      {" "}
      {/* Reduced top padding */}
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 text-center">
        {/* Use Cases Bubble */}
        <div className="text-center mb-12">
          <div className="inline-block px-6 py-2 border border-gray-500 rounded-full">
            <h2 className="text-white text-lg md:text-xl">Use Cases</h2>
          </div>
        </div>

        {/* Cards Section */}
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-12">
          {useCases.map((useCase, index) => (
            <div
              key={index}
              className="bg-[#191919] p-8 rounded-lg shadow-lg hover:shadow-2xl transition duration-300 ease-in-out transform hover:-translate-y-2"
            >
              <div className="bg-[#252525] p-5 rounded-full mx-auto mb-6 flex items-center justify-center h-20 w-20">
                <img
                  src={useCase.icon}
                  alt={useCase.title}
                  className="max-h-full max-w-full object-contain"
                />
              </div>
              <h3 className="text-2xl font-bold text-white mb-4">
                {useCase.title}
              </h3>
              <p className="text-gray-400 text-sm md:text-base leading-relaxed">
                {useCase.description}
              </p>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default NewUseCasesSection;
