import { styled } from '@mui/material/styles';
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import classes from './ActionIcon.module.css';

const LightTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }} />
  ))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: 'rgba(0, 0, 0, 0.75)',
      color: 'rgba(255, 255, 255, 0.87)',
      boxShadow: theme.shadows[1],
      fontSize: 15,
    },
  }));

function ActionIcon(props) {
    return <LightTooltip title={props.tooltip}><img src={props.icon} alt="" onClick={props.onClick} className={classes.icon} /></LightTooltip>
}

export default ActionIcon;