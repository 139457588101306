import { useState } from "react";

import { getDisplayTimeStamp } from "../../utils/timeConversions";

import editIcon from "../../assets/Icons/edit-icon.png";
import deleteIcon from "../../assets/Icons/delete-icon.png";
import tickIcon from "../../assets/Icons/tick-icon.png";
import crossIcon from "../../assets/Icons/cross-icon.png";
import classes from "./Note.module.css";

function Note(props) {
  const [deleteConfirm, setDeleteConfirm] = useState(false);

  //const parser = new DOMParser();
  function handleDeleteClick() {
    setDeleteConfirm(true);
  }

  function handleEditNote() {
    props.onNoteClick(props.noteData.playedSeconds);
    props.onEditNote({
      noteId: props.noteId,
      noteData: props.noteData,
    });
  }

  function handleDeleteNote() {
    props.onDeleteNote(props.noteId);
  }

  function handleCancelDelete() {
    setDeleteConfirm(false);
  }

  function handleClick(e) {
    props.onNoteClick(props.noteData.playedSeconds);
  }

  function preventDefault(e) {
    e.stopPropagation();
  }

  return (
    <div className={classes.note} onClick={handleClick}>
      <div className={classes.noteHeader}>
        <span className={classes.timeStamp}>
          {getDisplayTimeStamp(props.noteData.playedSeconds)}
        </span>
        {props.noteData.isAIGenerated && <span className={classes.ai}>AI</span>}
        <div className={classes.separator}></div>
        <div className={classes.actions} onClick={preventDefault}>
          <div className={classes.noteHeaderIcon} hidden={deleteConfirm}>
            <img src={editIcon} alt="" onClick={handleEditNote} />
          </div>
          <div className={classes.noteHeaderIcon} hidden={deleteConfirm}>
            <img src={deleteIcon} alt="" onClick={handleDeleteClick} />
          </div>
          <div className={classes.noteHeaderIcon} hidden={!deleteConfirm}>
            <img src={tickIcon} alt="" onClick={handleDeleteNote} />
          </div>
          <div className={classes.noteHeaderIcon} hidden={!deleteConfirm}>
            <img src={crossIcon} alt="" onClick={handleCancelDelete} />
          </div>
        </div>
      </div>
      <div onClick={handleClick} className={classes.noteBody}>
        <div
          dangerouslySetInnerHTML={{ __html: props.noteData.note.html }}
        ></div>
      </div>
    </div>
  );
}

export default Note;
