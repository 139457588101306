import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";

import classes from "./PlanDetails.module.css";
import checkedIcon from "../../assets/Icons/checked.png";

// const array = [
//     'Unlimited Videos',
//     'Unlimited Notes',
//     'Chrome Extension',
//     'Live Transcript',
//     'AI Generate',
//     'Export Notes (Coming Soon!)',
// ]

function PlanDetails(props) {
  const [isHovering, setIsHovering] = useState(false);
  const navigate = useNavigate();

  const userData = useSelector((state) => state.auth.userData);

  const handleMouseEnter = () => {
    setIsHovering(true);
  };

  const handleMouseLeave = () => {
    setIsHovering(false);
  };

  function handleClick(e) {
    e.preventDefault();

    if (props.price === "free") {
      navigate("/videos");
    } else {
      if (userData) props.openModal();
      else alert("Please Login to subscribe");
    }
  }

  return (
    <div className={`${classes["details-card"]}`}>
      <h3
        className={`${classes.header} ${"text-center p-3"}`}
        style={{ backgroundColor: props.color }}
      >
        {props.planName}
      </h3>
      <div className={classes.price}>
        <h1>
          {props.price === "free"
            ? "Free"
            : props.country !== "IN"
            ? "$" + props.price
            : "₹" + props.indiaPrice}
        </h1>
        {props.price !== "free" && props.price !== "149" && <p>/mo</p>}
      </div>
      <hr className={classes.divider} />
      <ul className={`${classes.features}`}>
        {props.features.map((feature) => {
          return (
            <li key={feature} className={`${"flex items-center"}`}>
              <img src={checkedIcon} alt="" />
              <span>{feature}</span>
            </li>
          );
        })}
      </ul>
      {(!userData || (userData && !userData.isPremiumUser)) && (
        <button
          onClick={handleClick}
          className={`${classes.btn}`}
          style={{
            backgroundColor: isHovering ? props.hoverColor : props.color,
          }}
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}
        >
          {props.price === "free" ? "Start Free" : "Upgrade Now!"}
        </button>
      )}
      {userData &&
        props.price !== "free" &&
        userData.isPremiumUser &&
        props.country !== "IN" && (
          <p className="text-center p-3">You're on the Premium Plan!</p>
        )}
      {userData &&
        props.price !== "free" &&
        userData.isPremiumUser &&
        props.country === "IN" && (
          <button
            onClick={(e) =>
              window.open(
                "https://billing.stripe.com/p/login/00g3cIcmZasb9TW144",
                "_blank"
              )
            }
            className={`${classes.btn}`}
            style={{
              backgroundColor: isHovering ? props.hoverColor : props.color,
            }}
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
          >
            Manage your subscription
          </button>
        )}
      {userData && props.price === "free" && userData.isPremiumUser && <p></p>}
    </div>
  );
}

export default PlanDetails;
