import { useState, useEffect } from "react";
import { collection, getDocs } from "firebase/firestore";
import { useSelector } from "react-redux";

import classes from "./AllNotes.module.css";
import { db } from "../../Firebase";
import Note from "./Note";

function notesCompare(a, b) {
  if (a.noteData.playedSeconds < b.noteData.playedSeconds) {
    return -1;
  }
  if (a.last_nom > b.last_nom) {
    return 1;
  }
  return 0;
}

function AllNotes(props) {
  const [notes, setNotes] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const userData = useSelector((state) => state.auth.userData);
  const isLoggedIn = useSelector((state) => state.auth.isLoggedIn);

  function handleDeleteNote(noteId) {
    var index = notes.findIndex(note => note.noteId === noteId);
    const newNotes = JSON.parse(JSON.stringify(notes));
    newNotes.splice(index, 1);
    setNotes(newNotes);
    props.onDeleteNote(noteId);
  }

  async function getNotes() {
    const fetchedNotes = [];
    const notesSnapshot = await getDocs(
      collection(
        db,
        "users",
        userData.uid,
        "videos",
        props.videoService + props.videoId,
        "notes"
      )
    );
    notesSnapshot.forEach((doc) => {
      //console.log(doc.id, " => ", doc.data());
      fetchedNotes.push({
        noteId: doc.id,
        noteData: doc.data(),
      });
    });
    fetchedNotes.sort(notesCompare);
    //console.log(fetchedNotes);
    setNotes(fetchedNotes);
    setIsLoading(false);
  }

  useEffect(() => {
    if(isLoggedIn) {
      getNotes();
    }
  }, [userData, props.videoService, props.videoId, props.AINotesGeneratedThisSession]);

  function handleNoteClick(seconds){
    props.onSeekToSeconds(seconds);
  }

  return (
    <div className={classes.notes}>
      {isLoggedIn && notes.length === 0 && isLoading === false && (
        <p className="text-center">
          You have not taken any notes. Click on the plus icon to start taking
          notes.
        </p>
      )}
      {isLoggedIn && notes.map((note) => (
        <Note key={note.noteId} noteId={note.noteId} noteData={note.noteData} onNoteClick={handleNoteClick} onDeleteNote={handleDeleteNote} onEditNote={props.onEditNote} />
      ))}
      {!isLoggedIn && <p className="text-center">
        Please login to view your notes
      </p>}
    </div>
  );
}

export default AllNotes;
