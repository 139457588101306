import { signInWithPopup, signOut, getAdditionalUserInfo } from "firebase/auth";
import { auth, provider, db } from "../Firebase";
import { authActions } from "../store/auth-slice";
import { setDoc, doc, getDoc } from "firebase/firestore";

export function signInGoogle() {
  return async (dispatch) => {
    try {
      signInWithPopup(auth, provider)
        .then(async (result) => {
          //const credential = GoogleAuthProvider.credentialFromResult(result);
          //const token = credential.accessToken;

          const user = result.user;
          const additionallUserInfo = getAdditionalUserInfo(result);
          const idToken = result._tokenResponse.idToken;

          console.log(result);

          try {
            await fetch(
              "https://zippynotes.co/api/login",
              //'http://127.0.0.1:5001/zippy-notes/us-central1/api/api/login',
              {
                method: "POST",
                body: `${idToken}`,
                credentials: "include",
              }
            );
          } catch (error) {
            console.log(error);
          }

          const userData = {
            uid: user.uid,
            displayName: user.displayName,
            email: user.email,
            photoURL: user.photoURL,
            isPremiumUser: false,
            AIGenerateLeft: 3,
            SaveAsPDFLeft: 5,
          };
          if (additionallUserInfo.isNewUser) {
            await setDoc(doc(db, "users", user.uid), {
              ...userData,
            });

            await fetch(
              "https://us-central1-zippy-notes.cloudfunctions.net/stripe/create-customer",
              {
                method: "POST",
                headers: {
                  "Content-Type": "application/json",
                },
                body: JSON.stringify({
                  email: user.email,
                  uid: user.uid,
                }),
              }
            );
          }
          const userSnapshot = await getDoc(doc(db, "users", user.uid));
          const data = userSnapshot.data();
          const userDataWithIdToken = {
            uid: user.uid,
            displayName: user.displayName,
            email: user.email,
            photoURL: user.photoURL,
            idToken: idToken,
            isPremiumUser: data.isPremiumUser,
            AIGenerateLeft: data.AIGenerateLeft,
            SaveAsPDFLeft: data.SaveAsPDFLeft,
          };

          dispatch(authActions.signIn(userDataWithIdToken));
        })
        .catch((error) => {
          console.log(error);
        });
    } catch (error) {
      console.log(error);
    }
  };
}

export function signOutGoogle() {
  return async (dispatch) => {
    signOut(auth)
      .then(() => {
        dispatch(authActions.signOut());

        async function logout() {
          try {
            const res = await fetch("https://zippynotes.co/api/logout", {
              method: "POST",
            });
            return res;
          } catch (e) {
            console.log(e);
          }
        }

        logout().then((res) => {
          console.log(res);
        });
      })
      .catch((error) => {
        console.log(error);
      });
  };
}

// export function verifyAuth() {
//     return async (dispatch) => {
//         onAuthStateChanged(auth, (user) => {
//             if(user) {
//                 dispatch(authActions.signIn({
//                     displayName: user.displayName,
//                     email: user.email,
//                     photoURL: user.photoURL,
//                 }));
//             }
//             else{
//                 dispatch(authActions.signOut());
//             }
//         })
//     }
// }
