import { useState } from "react";
import { ColorRing } from "react-loader-spinner";
import { PayPalScriptProvider, PayPalButtons } from "@paypal/react-paypal-js";

import classes from "./SubscriptionModal.module.css";

function SubscriptionModal(props) {
  const [stripeLinkLoading, setStripeLinkLoading] = useState(false);

  // function handleStripeRedirect() {
  //   window.open(props.stripeLink, '_blank');
  // }

  async function handleStripeClick() {
    setStripeLinkLoading(true);

    await props.onStripeClick();

    setStripeLinkLoading(false);
  }

  return (
    <div>
      <div className={classes.backdrop} onClick={props.onClose} />

      <div className={classes.modal}>
        {props.lifetime === false && <h2>Subscribe to ZippyNotes Premium</h2>}
        {props.lifetime === true && <h2>Buy ZippyNotes Premium</h2>}
        <p className="mb-5">{props.plan}</p>
        <p className="mb-4">
          {props.price !== "149"
            ? props.userCountry !== 'IN' ? "$" + props.price + "/mo" : "₹" + props.indiaPrice + "/mo"
            : props.userCountry !== 'IN' ? "$" + props.price : "₹" + props.indiaPrice}
        </p>

        {/* <PayPalScriptProvider
          options={{
            clientId:
              "AVSHsOjbvAQFs0tA9HUVTTnOWcjogqKMLDj1yl-JXj8RtfOXTZs8LFoOawE4JI1Ss-4EzD1vS4EmfLCl",
            currency: "USD",
            intent: "subscription",
            vault: true,
          }}
        > */}
        {props.userCountry !== "IN" && (
          <>
            <PayPalButtons
              style={{ layout: "horizontal" }}
              createOrder={props.value === 3 ? props.createOrder : null}
              createSubscription={props.value !== 3 ? props.createSubscription : null}
              onApprove={props.onApprove}
              fundingSource="paypal"
            />
            <PayPalButtons
              fundingSource="card"
              createOrder={props.value === 3 ? props.createOrder : null}
              createSubscription={props.value !== 3 ? props.createSubscription : null}
              onApprove={props.onApprove}
            />
          </>
        )}

        {props.userCountry === "IN" && (
          <button onClick={handleStripeClick} className={classes.stripeButton}>
            {props.lifetime ? "Purchase" : "Subscribe"}
            {stripeLinkLoading ? (
              <ColorRing
                height="26"
                width="26"
                visible={true}
                ariaLabel="blocks-loading"
                wrapperStyle={{}}
                wrapperClass="blocks-wrapper"
                colors={["#FFFFFF", "#FFFFFF", "#FFFFFF", "#FFFFFF", "#FFFFFF"]}
              />
            ) : null}
          </button>
        )}

        <p className={`${"mt-1"} ${classes.cancel}`} onClick={props.onClose}>
          Cancel
        </p>
        {/* </PayPalScriptProvider> */}
      </div>
    </div>
  );
}

export default SubscriptionModal;
