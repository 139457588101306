import React from "react";

import ASULogo from "../../assets/Institutions Logo/ASU-EndorsedLogo.jpg";
import UCSCLogo from "../../assets/Institutions Logo/UCSC Logo.jpg";
import BITSLogo from "../../assets/Institutions Logo/bits logo final.png";
import TAMLogo from "../../assets/Institutions Logo/TAM Logo.jpg";
import OSULogo from "../../assets/Institutions Logo/OSULogo.png";

const SocialProofSection = () => {
  const institutions = [
    { name: "Arizona State University", logo: ASULogo },
    { name: "University of California, Santa Cruz", logo: UCSCLogo },
    { name: "BITS Pilani", logo: BITSLogo },
    { name: "Texas A&M University", logo: TAMLogo },
    { name: "Oregon State University", logo: OSULogo },
  ];

  return (
    <section className="bg-black py-12">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 text-center">
        <p className="text-gray-400 text-lg mb-12">
          {" "}
          {/* Increased margin-bottom */}
          Join 5400+ learners from leading institutions
        </p>
        <div className="flex flex-wrap justify-center gap-x-8 gap-y-0">
          {" "}
          {/* Decreased vertical gap */}
          {institutions.map((institution, index) => (
            <div
              key={index}
              className="flex items-center justify-center h-12 sm:h-16 md:h-20 lg:h-24 w-24 sm:w-32 md:w-40 lg:w-48"
            >
              <img
                src={institution.logo}
                alt={institution.name}
                className="max-h-full max-w-full object-contain"
              />
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default SocialProofSection;
